import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../pages/NotFoundPage';
import RegisterPage from '../pages/RegisterPage';
import { history } from '../store';
import AdminRoutes from './AdminRoutes';
import LogoutPage from '../pages/LogoutPage';
import Alert from 'react-s-alert';

const HomeRoutes = () => {
  window.location.href = '/swagger-ui.html';
};

const BaseRoutes = () => (
  <ConnectedRouter history={history}>
    <>
      <Alert effect="slide" position="bottom-right" stack />
      <Switch>
        <Route path="/admin" component={AdminRoutes} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/register/:inviteCode" component={RegisterPage} />
        <Route exact path="/" component={HomeRoutes} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  </ConnectedRouter>
);

export default BaseRoutes;
