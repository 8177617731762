import { func } from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../actions/auth';

class LogoutPage extends Component {
  static propTypes = {
    dispatch: func.isRequired,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(logout())
      .then(() => {
        window.location.href = '/';
      })
      .catch((err) => {
        console.error('logout error:', err);
      });
  }

  render() {
    return null;
  }
}

export default connect()(withRouter(LogoutPage));
