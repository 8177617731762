import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Alert from 'react-s-alert';
import { func, object } from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';

const useStyles = makeStyles((theme) => ({
  instructions: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: '1.1rem',
    fontWeight: 400,
    margin: '1rem 0',
  },
  codeBlock: {
    backgroundColor: grey[200],
    padding: theme.spacing(1, 2),
    borderRadius: 3,
    fontFamily: 'monospace',
    fontSize: '1rem',
    color: grey[800],
    margin: theme.spacing(2),
  },
}));

const ApiCredentialsDialog = ({ credentials, onClose }) => {
  const classes = useStyles();

  if (!credentials) {
    return null;
  }

  const authHeaderValue = credentials && `Basic ${btoa(`${credentials.clientId}:${credentials.password}`)}`;

  const plainTextInstructions = `API Client Credentials for Letters API
======================================
API Client credentials for: ${credentials.name}.

Raw credentials
-----------------
clientId: ${credentials.clientId}
password: ${credentials.password}

Authorization header for API calls
----------------------------------
This is generated by having the part after 'Basic ' as the base64 encoded string value of '<clientId>:<password>'.

authorization: ${authHeaderValue}

Sample basic endpoint
---------------------
GET to /api/v1 will return the version and status of API and is secured.

Sample cURL
-----------
curl '${window.location.origin}/api/v1' -H 'authorization: ${authHeaderValue}'
  `;

  return (
    <>
      <ConfirmationDialog
        title="API Client Credentials"
        maxWidth="md"
        open={!!credentials}
        onConfirm={onClose}
        customButton={() => (
          <CopyToClipboard text={plainTextInstructions}>
            <Button variant="text" onClick={() => Alert.success('Copied instructions to clipboard')}>
              Copy to clipboard
            </Button>
          </CopyToClipboard>
        )}
        confirmOnly
      >
        {credentials && (
          <div className={classes.instructions}>
            <p>
              <strong>Important! </strong> Take down these details because you will not be able to retrieve them later.
            </p>
            <p className={classes.heading}>Raw credentials</p>
            <div className={classes.codeBlock}>
              <ul className="unstyled-list">
                <li>clientId: {credentials.clientId}</li>
                <li>password: {credentials.password}</li>
              </ul>
            </div>
            <p className={classes.heading}>Authorization header for API calls</p>
            <p>
              This is generated by having the part after 'Basic ' as the base64 encoded string value of{' '}
              <code>'&lt;clientId&gt;:&lt;password&gt;'</code>.
            </p>
            <div className={classes.codeBlock}>
              <ul className="unstyled-list">
                <li>authorization: {authHeaderValue}</li>
              </ul>
            </div>
            <p className={classes.heading}>Sample basic endpoint</p>
            <p>
              <code>GET</code> to <code>/api/v1</code> will return the version and status of API and is secured.
            </p>
            <p className={classes.heading}>Sample cURL</p>
            <div className={classes.codeBlock}>
              curl '{window.location.origin}/api/v1' -H 'authorization: {authHeaderValue}'
            </div>
          </div>
        )}
      </ConfirmationDialog>
    </>
  );
};

ApiCredentialsDialog.propTypes = {
  onClose: func.isRequired,
  credentials: object,
};

ApiCredentialsDialog.defaultProps = {
  credentials: undefined,
};

export default ApiCredentialsDialog;
