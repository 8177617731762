import { Button, IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Add as AddIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import Progress from '../../components/common/Progress';
import ApiClientList from '../../components/api-clients/ApiClientList';
import api from '../../services/api';
import Alert from 'react-s-alert';
import FormikDialog from '../../components/forms/FormikDialog';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import * as Yup from 'yup';
import ApiCredentialsDialog from '../../components/api-clients/ApiCredentialsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  header: {
    position: 'relative',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(16),
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
  link: {
    padding: theme.spacing(0, 1),
  },
  actions: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(),
    },
  },
  subActions: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: 0,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 2),
    margin: 0,
  },
}));

const ApiClientsPage = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [apiClients, setApiClients] = useState(null);

  const fetchApiClients = () => {
    setApiClients(null);
    api.apiClients
      .list()
      .then(setApiClients)
      .catch((error) => Alert.error(`Error fetching API clients: ${error.message}`));
  };

  const createApiClient = ({ name }) =>
    api.apiClients
      .create(name)
      .then((result) => {
        setDialogOpen(false);
        setCredentials(result);
      })
      .then(fetchApiClients);

  useEffect(() => {
    fetchApiClients();
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">API clients</Typography>
        <div className={classes.actions}>
          <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
            <AddIcon className={classes.iconLeft} />
            Create
          </Button>
        </div>
        <div className={classes.subActions}>
          <Tooltip title="Refresh" aria-label="Refresh results">
            <div>
              <IconButton onClick={fetchApiClients} disabled={!apiClients}>
                <RefreshIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
      {apiClients ? <ApiClientList apiClients={apiClients} refreshApiClients={fetchApiClients} /> : <Progress />}

      <FormikDialog
        onSubmit={createApiClient}
        onCancel={() => setDialogOpen(false)}
        title="Create API Client"
        open={dialogOpen}
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
        })}
      >
        <Field component={TextField} name="name" label="Name" fullWidth autoFocus />
      </FormikDialog>

      <ApiCredentialsDialog credentials={credentials} onClose={() => setCredentials(null)} />
    </Paper>
  );
};

export default ApiClientsPage;
