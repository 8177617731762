import React from 'react';
import { withStyles, withWidth } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import { connect } from 'react-redux';
import { array, arrayOf, bool, node, object, oneOfType, string } from 'prop-types';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-default.css';
import MenuDrawer from './menu/MenuDrawer';
import Header from './Header';
import { getOpenDrawer } from '../../reducers/index';

const CoreLayout = ({ children, classes, menuItems, padded, isAdmin, openDrawerName, width }) => (
  <div className={cx(classes.root, { [classes.rootWithSidebar]: width !== 'xs' && openDrawerName === 'sidebar' })}>
    <Header isAdmin={isAdmin} hideMenu={!menuItems} />
    {menuItems ? <MenuDrawer menuItems={menuItems} isAdmin={isAdmin} /> : null}
    <div className={cx(classes.content, { [classes.contentPadded]: padded })}>{children}</div>
  </div>
);

CoreLayout.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  classes: object.isRequired,
  menuItems: array,
  padded: bool,
  isAdmin: bool,
  openDrawerName: string,
  width: string.isRequired,
};

CoreLayout.defaultProps = {
  padded: false,
  isAdmin: false,
  openDrawerName: null,
  menuItems: null,
};

const styles = (theme) => ({
  root: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: '100%',
    background: grey[200],
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    overflowY: 'scroll',
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentPadded: {
    padding: '94px 30px 15px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

export default connect((state) => ({
  openDrawerName: getOpenDrawer(state),
}))(withStyles(styles)(withWidth()(CoreLayout)));
