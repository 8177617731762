import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminLayout from '../pages/admin/AdminLayout';
import UserProfilePage from '../pages/admin/UserProfilePage';
import AsyncComponent from './AsyncComponent';
import { isAdminUser, loadRefData, loginRequired } from './hooks';
import { Redirect } from 'react-router';
import ManageUsersPage from '../pages/admin/ManageUsersPage';
import ApiClientsPage from '../pages/admin/ApiClientsPage';

const AdminRoutes = () => (
  <AsyncComponent asyncActions={[loginRequired, isAdminUser, loadRefData]}>
    <AdminLayout>
      <Switch>
        <Redirect exact from="/admin" to="/admin/api-clients" />
        <Route exact path="/admin/api-clients" component={ApiClientsPage} />
        <Route exact path="/admin/users" component={ManageUsersPage} />
        <Route exact path="/admin/users/:userId" component={UserProfilePage} />
      </Switch>
    </AdminLayout>
  </AsyncComponent>
);

export default AdminRoutes;
