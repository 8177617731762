/* eslint-disable react/no-array-index-key */
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import * as model from '../../model';

const useStyles = makeStyles((theme) => ({
  formError: {
    padding: theme.spacing(1, 0),
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));

const FormError = ({ value }) => {
  const classes = useStyles();
  const errors = (value && value.messages) || (value && [value]);
  return (
    <div>
      {errors &&
        errors.map((err, index) => (
          <p key={index} className={classes.formError}>
            {err}
          </p>
        ))}
    </div>
  );
};

FormError.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, model.error]),
};

FormError.defaultProps = {
  value: undefined,
};

export default FormError;
