import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Tooltip, withStyles, withWidth } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { closeDrawer } from '../../../actions/drawer';
import { getOpenDrawer } from '../../../reducers';

const ListItemCustom = ({ classes, path, Icon, title, exact, isAdmin, closeMenu, width, pathname, openDrawerName }) => (
  <Tooltip
    id={path}
    title={title}
    placement="right"
    disableHoverListener={openDrawerName === 'sidebar'}
    disableTouchListener={openDrawerName === 'sidebar'}
    disableFocusListener={openDrawerName === 'sidebar'}
  >
    <ListItem
      button
      className={cx(classes.listItem, {
        [classes.listItemIsAdmin]: isAdmin,
        [classes.listItemActive]: exact ? pathname === path : pathname.indexOf(path) !== -1,
      })}
      to={path}
      component={Link}
      onClick={() => {
        if (width === 'xs') closeMenu('sidebar');
      }}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <Icon />
      </ListItemIcon>
      <ListItemText disableTypography className={classes.listItemText} primary={title} />
    </ListItem>
  </Tooltip>
);

ListItemCustom.propTypes = {
  classes: PropTypes.object,
  path: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  title: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  isAdmin: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  openDrawerName: PropTypes.string,
};

ListItemCustom.defaultProps = {
  classes: {},
  exact: true,
  isAdmin: false,
  openDrawerName: null,
};

const styles = () => ({
  listItem: {
    width: 'auto',
    transition: 'all 300ms linear',
    '&:hover': {
      backgroundColor: '#9c27b0',
      '& $listItemText, & $listItemIcon': {
        color: '#fff',
      },
    },
    '& $listItemText, & $listItemIcon': {
      color: '#000',
    },
  },
  listItemActive: {
    backgroundColor: '#9c27b0',
    '& $listItemText, & $listItemIcon': {
      color: '#fff',
    },
  },
  listItemIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  listItemText: {
    color: '#FFFFFF',
  },
  listItemIsAdmin: {
    '&$listItemActive, &:hover': {
      backgroundColor: `${grey[300]} !important`,
      '& $listItemText, & $listItemIcon': {
        color: '#000 !important',
      },
    },
    '& $listItemText, & $listItemIcon': {
      color: '#000 !important',
    },
  },
});

export default connect(
  (state) => ({
    openDrawerName: getOpenDrawer(state),
  }),
  {
    closeMenu: closeDrawer,
  },
)(withWidth()(withStyles(styles)(ListItemCustom)));
