/* eslint-disable no-console */
import Cookies from 'universal-cookie';

const baseUrl = '/api';

const cookies = new Cookies();

const commonHeaders = () => ({
  Pragma: 'no-cache',
  'Cache-Control': 'no-cache', // required so IE11 does not automatically cache all GET requests
  'X-Requested-With': 'XMLHttpRequest', // Suppress the gray basic auth dialog in the browser on 401
  'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'), // Ensure CSRF token is sent with every request
});

export const formEncode = (obj) =>
  Object.keys(obj)
    .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
    .join('&');

export const getUrl = (path) => `${baseUrl}${path}`;

export const request = (path, method = 'GET', body = null, headers = {}) => {
  const url = getUrl(path);

  console.log(`${method} ${url}`);

  const config = {
    method,
    headers: { ...commonHeaders(), ...headers },
    credentials: 'include',
  };

  // Edge browsers will fail silently if you give a body, even a null one, to a GET request
  if (body) {
    config.body = body;
  }

  return fetch(url, config).then((response) => {
    if (response.ok) {
      return response;
    }

    return response.text().then((text) => {
      let error;

      try {
        // Attempt to parse body as JSON, fallback to plain text if parsing fails
        const data = JSON.parse(text);
        error = new Error(data.message);
        error.type = data.error;
        error.messages = data.messages;
      } catch (e) {
        // Fallback to plain text
        error = new Error(response.statusText);
      }

      error.status = response.status;
      error.payload = text;

      throw error;
    });
  });
};

const hasHeader = (headers = {}, headerName) => Object.keys(headers).some((key) => key.toLowerCase() === headerName.toLowerCase());

const requestWithData = (path, method, data, headers = {}) => {
  const headerContentType = 'Content-Type';
  // Don't modify for FormData or request with existing content-type header set
  if (data instanceof FormData || hasHeader(headers, headerContentType)) {
    return request(path, method, data, headers);
  }
  // Otherwise default to JSON
  return request(path, method, JSON.stringify(data), {
    [headerContentType]: 'application/json',
    ...headers,
  });
};

export const requestJSON = (path, method, data, headers = {}) =>
  (data ? requestWithData(path, method, data, headers) : request(path, method, null, headers))
    .then((response) => response.text())
    .then((responseText) => responseText && JSON.parse(responseText));

export const getJSON = (path, data, headers = {}) => requestJSON(path, 'GET', data, headers);
export const postJSON = (path, data, headers = {}) => requestJSON(path, 'POST', data, headers);
export const putJSON = (path, data, headers = {}) => requestJSON(path, 'PUT', data, headers);
export const deleteJSON = (path, data, headers = {}) => requestJSON(path, 'DELETE', data, headers);

export const uploadFile = (path, method, file, fileParam = 'file') => {
  const formData = new FormData();
  formData.set(fileParam, file);
  return requestJSON(path, method, formData);
};

export const uploadToStorage = (url, file) => {
  const init = {
    method: 'PUT',
    headers: { 'Content-Type': file.type },
    body: file,
    mode: 'cors',
  };

  return fetch(url, init).then((response) => {
    if (response.ok) {
      return response.json();
    }

    const error = new Error(response.statusText);
    error.status = response.status;
    error.payload = response.json();
    throw error;
  });
};
