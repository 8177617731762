import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Typography, Tooltip } from '@material-ui/core';
import { Warning as WarningIcon, Autorenew as RegenerateIcon, DeleteForever as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { grey, amber } from '@material-ui/core/colors';
import ConfirmationDialog from '../common/ConfirmationDialog';
import api from '../../services/api';
import ApiCredentialsDialog from './ApiCredentialsDialog';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    backgroundColor: '#fff',
  },
  noData: {
    textAlign: 'center',
    color: grey[700],
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  warningIcon: {
    color: amber[700],
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    fontSize: '3.5rem',
  },
  rowActionsCell: {
    textAlign: 'end',
  },
  deleteClientButton: {
    marginRight: theme.spacing(2),
  },
  passwordResetAlertError: {
    marginTop: theme.spacing(1),
  },
}));

const ApiClientList = ({ apiClients, refreshApiClients }) => {
  const classes = useStyles();
  const [selectedApiClient, updateSelectedApiClient] = useState(null);
  const [newCredentials, updateNewCredentials] = useState(null);
  const [apiError, updateApiError] = useState(null);
  const [submitting, updateSubmitting] = useState(false);
  const [selectedApiClientForDeletion, updateSelectedApiClientForDeletion] = useState(null);

  const clearApiState = () => {
    updateApiError(null);
    updateSubmitting(false);
  };

  const clearResetPasswordState = () => {
    updateSelectedApiClient(null);
    clearApiState();
  };

  const clearDeleteClientState = () => {
    updateSelectedApiClientForDeletion(null);
    clearApiState();
  };

  const resetPassword = () => {
    updateApiError(null);
    updateSubmitting(true);
    api.apiClients
      .resetPassword(selectedApiClient.clientId)
      .then((response) => {
        updateNewCredentials(response);
        clearResetPasswordState();
      })
      .catch(updateApiError)
      .finally(() => updateSubmitting(false));
  };

  const deleteApiClient = () => {
    updateApiError(null);
    updateSubmitting(true);
    console.table(selectedApiClientForDeletion);
    api.apiClients
      .deleteClient(selectedApiClientForDeletion.clientId)
      .then(refreshApiClients)
      .then(clearDeleteClientState)
      .catch(updateApiError)
      .finally(() => updateSubmitting(false));
  };

  return (
    <>
      <ApiCredentialsDialog credentials={newCredentials} onClose={() => updateNewCredentials(null)} />
      <ConfirmationDialog
        confirmOnly
        submitting={submitting}
        open={!!selectedApiClient}
        onConfirm={resetPassword}
        onCancel={clearResetPasswordState}
        error={apiError}
        title={
          <span>
            <WarningIcon className={classes.warningIcon} /> Regenerate password for <em>{selectedApiClient && selectedApiClient.name}?</em>
          </span>
        }
      >
        <Typography>
          Are you sure you want to generate a new password? Your existing password will no longer work and will need to be updated with the new
          password.
        </Typography>
        {apiError && (
          <Alert className={classes.passwordResetAlertError} severity="error">
            Failed to reset password: {apiError.message}
          </Alert>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        confirmOnly
        submitting={submitting}
        open={!!selectedApiClientForDeletion}
        onConfirm={deleteApiClient}
        onCancel={clearDeleteClientState}
        title={
          <span>
            <WarningIcon className={classes.warningIcon} /> Delete <em>{selectedApiClientForDeletion && selectedApiClientForDeletion.name}?</em>
          </span>
        }
      >
        <Typography>
          Are you sure you want to delete this client? You will no longer be able to make API calls with this client, this action cannot be undone.
        </Typography>
        {apiError && (
          <Alert className={classes.passwordResetAlertError} severity="error">
            Failed to delete: {apiError.message}
          </Alert>
        )}
      </ConfirmationDialog>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Client Id</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {apiClients.length ? (
            apiClients.map((client) => (
              <TableRow key={client.clientId}>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.clientId}</TableCell>
                <TableCell className={classes.rowActionsCell}>
                  <Tooltip title={`Regenerate password for ${client.name}`} placement="top">
                    <IconButton onClick={() => updateSelectedApiClient(client)}>
                      <RegenerateIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={`Delete ${client.name}`} placement="top">
                    <IconButton className={classes.deleteClientButton} onClick={() => updateSelectedApiClientForDeletion(client)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell className={classes.noData} colSpan={4}>
                No API clients exist
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

ApiClientList.propTypes = {
  apiClients: PropTypes.array.isRequired,
  refreshApiClients: PropTypes.func.isRequired,
};

export default ApiClientList;
