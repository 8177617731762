import { createMuiTheme } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';

const spacing = 8;
const defaultGrey = grey[200];

export default createMuiTheme({
  typography: {},
  spacing,
  palette: {
    primary: blue,
    secondary: {
      light: '#aed581',
      main: '#30C530',
      dark: '#2a9a2a',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
      light: grey[100],
    },
  },
  overrides: {
    MuiButton: {
      root: {
        marginLeft: spacing,
        '&:nth-child(1)': {
          marginLeft: 0,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        color: '#FFFFFF',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: defaultGrey,
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '0.75rem',
        color: grey[600],
      },
      body: {
        fontSize: '0.8125rem',
      },
    },
    MuiCard: {
      root: {
        marginBottom: 15,
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: 'black',
      },
      title: {
        fontSize: 20,
        fontWeight: 600,
        backdgroundColor: 'black',
        color: 'white',
      },
      subheader: {
        color: 'white',
      },
    },
  },
});
