import { CircularProgress, withStyles } from '@material-ui/core';
import { object } from 'prop-types';
import React from 'react';

const Progress = ({ classes }) => (
  <div className={classes.progress}>
    <CircularProgress />
  </div>
);

Progress.propTypes = {
  classes: object.isRequired,
};

const styles = (theme) => ({
  progress: {
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
  },
});

export default withStyles(styles)(Progress);
