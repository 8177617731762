import { Button, Chip, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SendIcon from '@material-ui/icons/Send';
import { Link } from 'react-router-dom';
import Progress from '../../components/common/Progress';
import FormDialog from '../../components/forms/FormDialog';
import InviteUserForm from '../../components/forms/InviteUserForm';
import ChangePasswordForm from '../../components/forms/ChangePasswordForm';
import api from '../../services/api';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import { arrayOf, func } from 'prop-types';
import AppPropTypes from '../../components/AppPropTypes';
import { useSelector } from 'react-redux';
import { getReferenceDataRoles } from '../../reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  header: {
    position: 'relative',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(16),
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
  link: {
    padding: theme.spacing(0, 1),
  },
  roles: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  role: {
    margin: '4px !important',
    '&:first-child': {
      marginLeft: '0 !important',
    },
  },
  actions: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(),
    },
  },
}));

const UserTable = ({ users, onChangePassword }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>First name</TableCell>
        <TableCell>Last name</TableCell>
        <TableCell>Roles</TableCell>
        <TableCell>Status</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
    <TableBody>
      {users.map((user) => (
        <TableRow key={user.id}>
          <TableCell>
            <Link to={`/admin/users/${user.id}`}>{user.email}</Link>
          </TableCell>
          <TableCell>{user.firstName}</TableCell>
          <TableCell>{user.lastName}</TableCell>
          <TableCell>
            <div className="roles">
              {user.roles.map((role) => (
                <Chip key={role} className="role" label={role} />
              ))}
            </div>
          </TableCell>
          <TableCell>{user.status}</TableCell>
          <TableCell>
            <Button onClick={() => onChangePassword(user.id)}>Change password</Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

UserTable.propTypes = {
  users: arrayOf(AppPropTypes.user).isRequired,
  onChangePassword: func.isRequired,
};

const ManageUsersPage = () => {
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [changePasswordDialogUserId, setChangePasswordDialogUserId] = useState(null);
  const roles = useSelector((state) =>
    getReferenceDataRoles(state).map((input) => ({
      value: input.id,
      label: input.description,
    })),
  );

  const fetchUsers = () =>
    api.users
      .list()
      .then(setUsers)
      .catch((error) => Alert.error(`Error fetching users: ${error.message}`));

  useEffect(() => {
    fetchUsers();
  }, []);

  const openInviteUserDialog = () => setInviteUserDialogOpen(true);

  const closeInviteUserDialog = () => setInviteUserDialogOpen(false);

  const openChangePasswordDialog = (userId) => setChangePasswordDialogUserId(userId);

  const closeChangePasswordDialog = () => setChangePasswordDialogUserId(null);

  const handleInviteUser = (values) =>
    api.users
      .invite(values)
      .then(() => {
        Alert.success('User invite sent!');
        closeInviteUserDialog();
      })
      .then(fetchUsers)
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });

  const handleChangePassword = (values) => {
    api.users
      .changePassword(changePasswordDialogUserId, values.password)
      .then(() => {
        Alert.success('Password changed');
        closeChangePasswordDialog();
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">Manage users</Typography>
        <div className={classes.actions}>
          <Button variant="contained" color="primary" onClick={openInviteUserDialog}>
            <SendIcon className={classes.iconLeft} />
            Invite user
          </Button>
        </div>
      </div>
      {users ? <UserTable users={users} onChangePassword={openChangePasswordDialog} /> : <Progress />}
      <FormDialog
        title="Invite user"
        submitButtonText="Invite"
        formComponent={InviteUserForm}
        formName="inviteUser"
        open={inviteUserDialogOpen}
        onCancel={closeInviteUserDialog}
        onSubmit={handleInviteUser}
        roles={roles}
      />

      <FormDialog
        title="Change password"
        submitButtonText="Change"
        formComponent={ChangePasswordForm}
        formName="changePassword"
        open={!!changePasswordDialogUserId}
        onCancel={closeChangePasswordDialog}
        onSubmit={handleChangePassword}
      />
    </Paper>
  );
};

export default ManageUsersPage;
