import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';
import PeopleIcon from '@material-ui/icons/People';
import ShareIcon from '@material-ui/icons/Share';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-default.css';
import CoreLayout from '../../components/layouts/CoreLayout';

const AdminLayout = ({ children }) => {
  const menuItems = [
    {
      path: '/admin/api-clients',
      title: 'API clients',
      Icon: ShareIcon,
    },
    {
      path: '/admin/users',
      title: 'Manage users',
      Icon: PeopleIcon,
    },
  ];
  return (
    <CoreLayout menuItems={menuItems} padded isAdmin>
      {children}
    </CoreLayout>
  );
};

AdminLayout.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

export default AdminLayout;
