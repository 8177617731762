import { Button, Dialog, DialogContent, makeStyles, Slide } from '@material-ui/core';
import PropTypes, { arrayOf, node, oneOfType } from 'prop-types';
import React, { useState } from 'react';
import cx from 'classnames';
import DialogTitleWithClose from '../common/DialogTitleWithClose';
import { Form, Formik } from 'formik';
import FormError from './FormError';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="left" {...props} />);

const useStyles = makeStyles((theme) => ({
  allowOverflow: {
    overflowY: 'visible',
  },
  padded: {
    paddingRight: theme.spacing(2),
  },
  formFields: {
    paddingBottom: theme.spacing(),
  },
  dialogActions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    textAlign: 'right',
  },
}));

const FormikDialog = ({
  title,
  open,
  cancelButtonText,
  submitButtonText,
  deleteButtonText,
  onCancel,
  onDelete,
  maxWidth,
  allowOverflow,
  onSubmit,
  initialValues,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const handleDelete = () => onDelete && onDelete(initialValues);
  const [serverError, setServerError] = useState(null);

  // If we ever have more state add it here to reset ... this is if a user cancels the state should be reset
  const resetAllState = () => setServerError(null);

  const handleSubmit = (vals) => {
    setServerError(null);
    return Promise.resolve(onSubmit(vals)).catch((err) => {
      setServerError(err);
      throw err;
    });
  };

  const handleCancel = () => {
    resetAllState();
    return onCancel();
  };

  return (
    <Dialog
      classes={{ paper: allowOverflow ? classes.allowOverflow : '' }}
      title={title}
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={Transition}
    >
      <DialogTitleWithClose title={title} onClose={handleCancel} />
      <DialogContent className={cx({ [classes.allowOverflow]: allowOverflow })}>
        <FormError value={serverError} />
        <Formik initialValues={initialValues} onSubmit={handleSubmit} {...rest}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <div>
                <div className={classes.formFields}>{children}</div>
                <div className={classes.dialogActions}>
                  <Button variant="text" onClick={handleCancel}>
                    {cancelButtonText}
                  </Button>
                  {onDelete && (
                    <Button variant="text" secondary onClick={handleDelete}>
                      {deleteButtonText}
                    </Button>
                  )}
                  <Button variant="text" color="primary" disabled={isSubmitting} onClick={submitForm}>
                    {submitButtonText}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

FormikDialog.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  initialValues: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  maxWidth: PropTypes.string,
  allowOverflow: PropTypes.bool,
};

FormikDialog.defaultProps = {
  open: false,
  cancelButtonText: 'Cancel',
  submitButtonText: 'Submit',
  deleteButtonText: 'Delete',
  maxWidth: 'sm',
  onDelete: undefined,
  allowOverflow: false,
};

export default FormikDialog;
