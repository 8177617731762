import { postJSON, getJSON, putJSON, deleteJSON } from './http';

const create = (name) => postJSON('/api-clients', { name });
const resetPassword = (clientId) => putJSON(`/api-clients/${clientId}/reset-password`, {});
const deleteClient = (clientId) => deleteJSON(`/api-clients/${clientId}`, {});
const list = () => getJSON('/api-clients');

export default {
  list,
  create,
  resetPassword,
  deleteClient,
};
