import { Avatar, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BlankProfile from '../../../images/blank_profile.png';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 75,
    height: 75,
  },
  avatarExtraSmall: {
    width: 24,
    height: 24,
  },
  avatarSmall: {
    width: 35,
    height: 35,
  },
  avatarLarge: {
    width: 150,
    height: 150,
  },
}));

export const deriveAvatar = (image) => (image ? image.requestLink : BlankProfile);

const AppAvatar = ({ image, tooltip, size }) => {
  const classes = useStyles();

  const wrapInToolTipIfRequired = (children) => {
    if (image && tooltip) {
      return <Tooltip title={tooltip}>{children}</Tooltip>;
    }

    return children;
  };

  return wrapInToolTipIfRequired(
    <Avatar
      className={cx(
        classes.avatar,
        { [classes.avatarExtraSmall]: size === 'xs' },
        { [classes.avatarSmall]: size === 'sm' },
        { [classes.avatarLarge]: size === 'lg' },
      )}
      alt="Avatar"
      src={deriveAvatar(image)}
    />,
  );
};

AppAvatar.propTypes = {
  image: PropTypes.object,
  tooltip: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

AppAvatar.defaultProps = {
  image: null,
  tooltip: null,
  size: 'md',
};

export default AppAvatar;
